import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout.js'

const PrivacyPolicy = () => {
	return (
		<Layout>
			<div className="PrivacyPolicy">
				<div className="container">
					<article className="content">
						<h1 className="title">Privacy Policy</h1>
						<h2 className="subtitle">Maytronics Australia Privacy Policy</h2>
						<small>Effective Date: 21 August 2018.</small>
						<h3>1. Preamble</h3>
						<p>
							This Privacy Policy explains the collection, use, processing,
							transferring and disclosure of personal information by Maytronics
							Australia Pty Ltd (“MA” or “Maytronics”), a Australian water
							technologies company.{' '}
						</p>
						<p>
							This Privacy Policy is incorporated into and made part of
							Maytronics Australia Master Terms of Use (“Master Terms”) located
							at <Link to="/terms">Terms</Link>.
						</p>
						<p>
							Unless otherwise noted on a particular website or service hosted
							by Maytronics Australia, this Privacy Policy applies to your use
							of all websites that Maytronics Australia operates. These include
							but are not limited to{' '}
							<a
								href="https://maytronics.com.au/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://maytronics.com.au
							</a>
							,{' '}
							<a
								href="https://mydolphin.com.au/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.com.au
							</a>
							,{' '}
							<a
								href="https://mydolphin.co.nz"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.co.nz
							</a>
							,{' '}
							<a
								href="https://mydolphin.co.za"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.co.za
							</a>
							,{' '}
							<a
								href="https://mineralswim.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mineralswim.com
							</a>
							, together with all other subdomains thereof, (collectively, the
							“Websites”). This Privacy Policy also applies to all products,
							information, and services provided through the Websites, including
							without limitation the license chooser, legal tools, the Login
							Services (defined below), (together with the Websites, the
							“Services”).{' '}
						</p>
						<p>
							Please note that Maytronics Australia does <b>not</b> operate the
							website at{' '}
							<a
								href="https://maytronics.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://maytronics.com
							</a>
							.
						</p>
						<p>
							By accessing or using any of the Services, you are accepting and
							agreeing to the practices described in this Privacy Policy.
						</p>
						<h3>2. Our Principles</h3>
						<p>
							Maytronics Australia has designed this policy to be consistent
							with the following principles :
						</p>
						<ul>
							<li>
								Privacy policies should be human readable and easy to find.
							</li>
							<li>
								Data collection, storage, and processing should be simplified as
								much as possible to enhance security, ensure consistency, and
								make the practices easy for users to understand.{' '}
							</li>
							<li>
								Data practices should always meet the reasonable expectations of
								users.{' '}
							</li>
						</ul>
						<h3>3. Personal Information MA Collects and How it is Used</h3>
						<p>
							As used in this policy, “personal information” means information
							that would allow someone to identify you, including your name,
							email address, IP address, or other information from which someone
							could deduce your identity.
						</p>
						<p>
							MA collects and uses personal information in the following ways:{' '}
						</p>
						<p>
							<b>Website and Fundraising Analytics: </b>
							When you visit our Websites and use our Services, MA collects some
							information about your activities through tools such as Google
							Analytics. The type of information that we collect focuses on
							general information such as country or city where you are located,
							pages visited, time spent on pages, heat-map of visitors’ activity
							on the site, information about the browser you are using, etc. MA
							collects and uses this information pursuant to our legitimate
							interest in enhancing the security and utility of our Services.
							The information we gather and process is used in the aggregate to
							spot trends without deliberately identifying individuals, except
							in cases where you engage in a transaction with Maytronics
							Australia by donating money, purchasing producst, or seeking
							services in our network. In those cases, MA retains certain
							information about your visit to the Services pursuant to its
							legitimate interest in understanding its market of buyers for
							financial purposes, and this information is stored in connection
							with other personal information you provide to MA.{' '}
						</p>
						<p>
							Note that you can learn about Google’s practices in connection
							with its analytics services and how to opt out of it by
							downloading the Google Analytics opt-out browser add-on, available
							at{' '}
							<a
								href="https://tools.google.com/dlpage/gaoptout"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://tools.google.com/dlpage/gaoptout
							</a>
							. If you would like to opt out of MA’s analytics, please contact{' '}
							<a href="mailto:web.au@maytronics.com">web.au@maytronics.com</a>{' '}
							with your request.
						</p>
						<p>
							<b>Information from Cookies: </b>
							We and our service providers (for example, Google Analytics as
							described above) may collect information using cookies or similar
							technologies for the purposes described above and below. Cookies
							are pieces of information that are stored by your browser on the
							hard drive or memory of your computer or other Internet access
							device. Cookies may enable us to personalise your experience on
							the Services, maintain a persistent session, passively collect
							demographic information about your computer, and monitor
							advertisements and other activities. The Websites may use
							different kinds of cookies and other types of local storage (such
							as browser-based or plugin-based local storage).
						</p>
						<p>
							<b>Log-In Services:</b> When you register to obtain a user account
							on any of the Services (any such person, a “Registered User”),
							including but not limited to the services available at
							https://mydolphin.com.au/shop, you will be asked to provide
							personal information to create your account and establish a
							password and profile. MA collects and uses this personal
							information pursuant to its legitimate interest in establishing
							and maintaining your account providing you with the features we
							provide Registered Users. We may use your email address to contact
							you regarding changes to this policy or other applicable policies.
							The name or nickname you provide in connection with your account
							may be used to attribute you in connection with any content you
							submit to any Service. In addition, whenever you use a MA Login
							Service to log into a Website or use the Services, our servers
							keep a plain text log of the Websites you visit and when you visit
							them.{' '}
						</p>
						<p>
							<b>Emails and Newsletters:</b> When you sign up to receive updates
							from Maytronics Australia or otherwise subscribe to one of our
							mailing lists, you will be asked to provide some personal
							information. MA collects and uses this personal information
							pursuant to its legitimate interest in providing news and updates
							to, and collaborating with, its users and distribution network.{' '}
						</p>
						<p>
							<b>Email Analytics:</b> When you receive communications from MA
							after signing up for any MA newsletter, campaign updates, or other
							ongoing email communications from MA, we use analytics to track
							whether you open the mail, click on the links, and otherwise
							interact with what we send. You may opt out of this tracking by
							choosing to get plain-text emails from MA. MA collects and uses
							this personal information pursuant to its legitimate interest in
							understanding the interests of its market of users and
							distribution network in order to provide more relevant news and
							updates.{' '}
						</p>
						<p>
							<b>Financials: </b>
							When you donate money to Maytronics Australia, purchase products
							not limited to{' '}
							<a
								href="https://mydolphin.com.au/shop/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.com.au/shop/
							</a>
							, or pay for attendance at an event or participation in the MA
							Elite program, you will be asked to provide personal information,
							including payment information. MA collects and uses this personal
							information pursuant to its legitimate interest in profiting to
							ensure the growth and sustainability of our business and, where
							applicable, to provide you with the products, services, or
							information you purchased.{' '}
						</p>
						<h3>4. Retention of Personal Information</h3>
						<p>
							The majority of the personal information collected and used as
							explained in Section 3 above is aggregated and stored in a central
							database provided by a third party service provider. MA aggregates
							this data pursuant to its legitimate interest in having
							information stored in a single location to minimize complexity,
							increase consistency in internal practices, better understand its
							market of users and distribution network, and enhance the security
							of the data.{' '}
						</p>
						<p>
							MA erases the web browser logs described above on a regular,
							rolling basis.
						</p>
						<h3>5. Access to Your Personal Information</h3>
						<p>
							You are generally entitled to access personal information that
							Maytronics Australia holds and to have inaccurate data corrected
							or removed to the extent MA still maintains it. In certain
							circumstances, you also may have the right to object for
							legitimate reasons to the processing or transfer of personal
							information. If you wish to exercise any of these rights, please
							write to{' '}
							<a href="mailto:web.au@maytronics.com">web.au@maytronics.com</a>{' '}
							explaining your request.
						</p>
						<h3>6. Disclosure of Your Personal Information</h3>
						<p>
							MA does not disclose personal information to third parties except
							as specified elsewhere in this policy and in the following
							instances:
						</p>
						<ol>
							<li>
								Maytronics Australia may share personal information with our
								contractors and service providers in order to undertake the
								activities described in Section 3.{' '}
							</li>
							<li>
								We may disclose your personal information to third parties in a
								good faith belief that such disclosure is reasonably necessary
								to (a) take action regarding suspected illegal activities; (b)
								enforce or apply our Master Terms and this Privacy Policy; (c)
								enforce our <Link to="/charter">Charter</Link>, including the
								Code of Conduct and policies contained and incorporated therein,
								or (d) comply with legal process, such as a search warrant,
								subpoena, statute, or court order.
							</li>
						</ol>
						<h3>7. Security of Your Personal Information</h3>
						<p>
							Maytronics Australia has implemented reasonable physical,
							technical, and organizational security measures for personal
							information that Maytronics Australia processes against accidental
							or unlawful destruction, or accidental loss, alteration,
							unauthorized disclosure or access, in compliance with applicable
							law. However, no website can fully eliminate security risks. Third
							parties may circumvent our security measures to unlawfully
							intercept or access transmissions or private communications. If
							any data breach occurs, we will post a reasonably prominent notice
							to the Websites and comply with all other applicable data privacy
							requirements including, when required, personal notice to you if
							you have provided and we have maintained an email address for you.
						</p>
						<p>
							The MA Login Services account systems have security risks in
							addition to those described above. Among other things, they are
							vulnerable to DNS attacks, and using any MA Login Service may
							increase the risk of phishing.{' '}
						</p>
						<h3>9. Third Party Service Providers</h3>
						<p>
							Maytronics Australia uses many third party service providers in
							connection with the Services, including website hosting services,
							database management, credit card processing, and many more. Some
							of these service providers may place session cookies on your
							computer, and they may collect and store your personal information
							on our behalf in accordance with the data practices and purposes
							explained above in Section 3.{' '}
						</p>
						<h3>10. Third Party Sites</h3>
						<p>
							The Services may provide links to a wide variety of third party
							websites. You should consult the respective privacy policies of
							these third-party websites. This Privacy Policy does not apply to,
							and we cannot control the activities of, such other websites.
						</p>
						<h3>
							11. <b>Transferring Data to Other Countries</b>
						</h3>
						<p>
							If you are accessing or using the Services in regions with laws
							governing data collection, processing, transfer and use, please
							note that when we use and share your data as specified in this
							policy, we may transfer your information to recipients in
							countries other than the country in which the information was
							originally collected. Those countries may not have the same data
							protection laws as the country in which you initially provided the
							information.{' '}
						</p>
						<p>
							The Services are currently hosted in the United States and
							Australia, which means your personal information may be located on
							servers in the United States and/or Australia. The majority of
							contractors that Maytronics Australia is using as of the effective
							date of this Privacy Policy are located in the United States and
							in Australia, but this may change from time to time.{' '}
						</p>
						<p>
							Data transferred from the European Union to the United States or
							outside the European Union will be made on the grounds of a
							certification to the E.U./U.S. Privacy Shield regime and/or a data
							transfer agreement based on the Standard Contractual Clauses
							approved of by the European Commission respectively, consistent
							with applicable data privacy requirements.
						</p>
						<h3>12. Changes to this Privacy Policy</h3>
						<p>
							We may occasionally update this Privacy Policy. When we do, we
							will provide you with notice of such update through (at a minimum)
							a reasonably prominent notice on the Websites and Services, and
							will revise the Effective Date below. We encourage you to
							periodically review this Privacy Policy to stay informed about how
							we are protecting, using, processing and transferring the personal
							information we collect.
						</p>
						<small>Effective Date: 21 August 2018.</small>
					</article>
				</div>
			</div>
		</Layout>
	)
}

export default PrivacyPolicy
